.app {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  color: #555;
  background-color: #fcfcfc;
}

main {
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
}

.section {
  margin-top: 5em;
}

.section__title {
  text-align: center;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.center {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .section {
    margin-top: 4em;
  }
}
